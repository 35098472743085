body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.coc-dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.coc-dropdown-item:focus, .coc-dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef;
}

.coc-dropdown-item.active, .coc-dropdown-item:active {
 	color: #fff;
    text-decoration: none;
    background-color: #003262;
}

.coc-test-dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    text-decoration: none;
}

.coc-test-dropdown-item:focus, .coc-test-dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef;
}

.coc-test-dropdown-item.active, .coc-test-dropdown-item:active {
    color: #000;
    text-decoration: none;
    background-color: #fdb515;
}

.coc-bs-switch > .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #fdb515;
    background-color: #fdb515;
}

.dropdown-menu {
  max-height: 242px;
  overflow-y: scroll;
}

.mc-dropdown-item {
    /* display: block; */
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.mc-dropdown-item:focus, .mc-dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef;
}

.mc-dropdown-item.active, .mc-dropdown-item:active {
 	color: #fff;
    text-decoration: none;
    background-color: #003262;
}

.table-po-detail > tbody > tr > td {
	border: 1px solid;
	border-color: lightgray;
}